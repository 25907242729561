@import "../../sass/main.scss";

.confirmationBody {
  height: 90vh;
  display: flex;
  flex-direction: column;

  .confirmationBlock {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mq(mobile-only) {
      background-color: white;
    }
    @include mq(tablet) {
      background-image: url("../../images/secondBackground.jpg");
    }

    .confirmationWrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      @include mq(tablet) {
        max-width: 600px;
        padding: 80px 50px;
        background-color: white;
        border-radius: 6px;
        border: 1px solid #e5e8ed;
        background: #fff;
        box-shadow: 0px 4px 4px -4px rgba(30, 33, 44, 0.05),
          0px 12px 10px -6px rgba(30, 33, 44, 0.08),
          0px 26px 24px -10px rgba(30, 33, 44, 0.1),
          0px 60px 80px -20px rgba(30, 33, 44, 0.16);
      }
      .icon {
        width: 120px;
        height: 120px;
        margin-bottom: 25px;
      }
      .firstText {
        font-size: 40px;
        font-weight: 500;
        margin-bottom: 15px;
      }

      .secondText {
        font-size: 22px;
        font-weight: 500;
      }
    }
  }
}
