@import "../../sass/main.scss";

.form {
  display: flex;
  flex-direction: column;
  max-width: 415px;
  margin-bottom: 30px;
  width: 100%;

  @include mq(tablet) {
    padding: 0 25px;
  }
  @include mq(desktop) {
    padding: 0 50px;
    margin-bottom: 50px;
  }

  .formLabel {
    color: #424551;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 7px;
    @include mq(desktop) {
      font-size: 14px;
    }
  }

  .formInput {
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #d7dadd;
    background: #f4f5f6;
    padding: 11px 16px;
    font-size: 13px;
    color: #3f3f3f;
    width: 100%;
    @include mq(desktop) {
      font-size: 14px;
      padding: 15px 20px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .formRadioWrapper {
    margin-bottom: 20px;
  }

  .formRadioInputLabelWrapper {
    display: flex;
    margin-bottom: 10px;
  }

  .formRadioLabel {
    margin-bottom: 0;
  }

  .formRadioInput {
    margin-right: 7px;
  }
}
