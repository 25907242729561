@import "../../sass/main.scss";

.footer {
  display: flex;
  align-items: center;
  padding: 20px 25px;
  background: #1e212c;
  @include mq(tablet) {
    padding: 25px 30px;
  }
  @include mq(desktop) {
    padding: 40px 10vw;
  }

  .footerLogoSvgs {
    @include mq(desktop) {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .footerLogo {
      display: block;
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 1px;
      margin-bottom: 10px;
      @include mq(desktop) {
        margin-right: 65px;
        margin-bottom: 0;
        font-size: 24px;
      }
    }

    .linksList {
      & > a {
        margin-right: 10px;
        &:hover {
          & > svg {
            fill: #ff5a30;
            opacity: 1;
          }
        }
        &:last-child {
          margin-right: 0;
        }
        & > svg {
          fill: #fff;
          opacity: 0.6;
          width: 24px;
          height: 24px;
          transition: all 150ms linear;
        }
      }
    }
  }

  .footerGoUp {
    display: flex;
    margin-left: auto;
    background-color: #ff5a30;
    border-radius: 50%;
    padding: 10px;
    transition: all 150ms linear;
    fill: white;
    &:hover {
      background: white;
      outline: 2px solid #ff5a30;
      fill: #ff5a30;
    }
    & > svg {
      width: 24px;
      height: 24px;
    }
  }
}
