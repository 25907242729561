@import "../../sass/main.scss";

.firstPart {
  position: relative;
  display: flex;
  height: min-content;
  @include mq(tablet) {
    margin: 0 28px 25px 28px;
  }
  @include mq(desktop) {
    padding: 0 33px 49px 33px;
  }

  .firstBackground {
    width: 100%;
  }

  .firstBlockText {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    font-weight: 700;
    transform: translate(-50%, -50%);

    @include mq(tablet) {
      top: 55%;
      left: 30%;
    }

    @include mq(desktop) {
      left: 35%;
    }
  }

  .firstBlockTitle {
    font-size: 40px;
    line-height: 130%;
    letter-spacing: 1px;
    text-align: center;
    @include mq(tablet) {
      text-align: left;
    }
    @include mq(desktop) {
      font-size: 72px;
    }
  }

  .firstBlockSecondTitle {
    font-size: 20px;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 10px;
    text-align: center;
    @include mq(tablet) {
      text-align: left;
    }
    @include mq(desktop) {
      margin-bottom: 22px;
    }
  }

  .firstBlocklink {
    display: block;
    font-size: 14px;
    padding: 10px 30px;
    letter-spacing: 0.5px;
    border-radius: 4px;
    color: white;
    background: #ff5a30;
    margin: 0 auto;
    text-align: center;
    width: fit-content;
    transition: all 150ms linear;
    @include mq(tablet) {
      font-size: 16px;
      margin: 0;
      padding: 10px 25px;
    }
    @include mq(desktop) {
      font-size: 16px;
      padding: 12px 30px;
    }
    &:hover {
      background: white;
      color: #ff5a30;
      outline: 2px solid #ff5a30;
    }
  }
}

.secondPart {
  position: relative;
  @include mq(tablet) {
    background-image: url("../../images/secondBackground.jpg");
    padding: 10vh 0;
  }
  @include mq(desktop) {
    padding: 20vh 0;
  }
}

.secondBackground {
  width: 100%;
  display: none;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 30px 20px 25px 20px;
  @include mq(tablet) {
    margin: auto;
    max-width: 80vw;
    box-shadow: 0px 4px 4px -4px rgba(30, 33, 44, 0.05),
      0px 12px 10px -6px rgba(30, 33, 44, 0.08),
      0px 26px 24px -10px rgba(30, 33, 44, 0.1),
      0px 60px 80px -20px rgba(30, 33, 44, 0.16);
    border-radius: 4px;
    border: 1px solid #e5e8ed;
  }
  @include mq(desktop) {
    padding: 48px 66px 37px 66px;
    width: 70vw;
    max-width: 1000px;
  }

  .formTitle {
    color: #1e212c;
    font-size: 25px;
    font-weight: 700;
    line-height: 150%;
    margin-bottom: 30px;
    text-align: center;
    @include mq(tablet) {
      font-size: 40px;
    }
  }

  .submitBtn {
    padding: 12px 30px;
    border-radius: 4px;
    border: 0;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.5px;
    margin-bottom: 30px;
    background: #ff5a30;
    color: #fff;
    transition: all 150ms linear;
    @include mq(desktop) {
      padding: 15px 45px;
      font-size: 16px;
    }
    &:hover {
      background: white;
      color: #ff5a30;
      outline: 2px solid #ff5a30;
    }
  }

  .formPolicyText {
    color: #424551;
    font-size: 10px;
    @include mq(desktop) {
      font-size: 14px;
    }
  }
}
