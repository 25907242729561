@mixin mq($range) {
  $mobile: 480px;
  $tablet: 768px;
  $desktop: 1100px;
  @if $range == mobile-only {
    @media (max-width: #{$tablet - 0.02}) {
      @content;
    }
  } @else if $range == tablet {
    @media (min-width: $tablet) {
      @content;
    }
  } @else if $range == tablet-only {
    @media (min-width: $tablet) and (max-width: #{$desktop - 0.02}) {
      @content;
    }
  } @else if $range == desktop {
    @media (min-width: $desktop) {
      @content;
    }
  }
}
