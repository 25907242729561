@import "../../sass/main.scss";

.header {
  display: flex;
  align-items: center;
  padding: 10px 20px;

  @include mq(tablet) {
    padding: 10px 30px;
  }

  @include mq(desktop) {
    padding: 25px 10%;
  }

  .headerTitle {
    font-size: 32px;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: 1px;
    color: #1e212c;
  }

  .headerLinksWrapper {
    display: flex;
    margin-left: auto;

    @include mq(mobile-only) {
      display: none;
    }
  }

  .headerLink {
    display: flex;
    align-items: center;
    margin-right: 36px;
    &:hover {
      & > .headerLinkText {
        color: #ff5a30;
        & > b {
          color: #ff5a30;
        }
      }
    }
    & > svg {
      margin-right: 12px;
      width: 30px;
      @include mq(desktop) {
        width: 40px;
      }
    }

    &:first-of-type {
      margin-right: 20px;
      @include mq(desktop) {
        margin-right: 36px;
      }
    }

    .headerLinkText {
      font-size: 18px;
      font-weight: 400;
      color: #1e212c;
      height: min-content;
      transition: all 150ms linear;
      & > b {
        font-size: 14px;
        color: #424551;
        transition: all 150ms linear;
      }
      @include mq(tablet) {
        font-size: 14px;
        & > b {
          font-size: 12px;
        }
      }
      @include mq(desktop) {
        font-size: 18px;
        & > b {
          font-size: 14px;
        }
      }
    }
  }
}
